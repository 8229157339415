@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

$body-bg: #002858;
$primary: #002858;
$secondary: #FF7500;
$font-family-base: 'Poppins', sans-serif;
$font-family-sans-serif: 'Poppins', sans-serif;
$spinner-width: 6rem;
$spinner-height: $spinner-width;
$footer-height: 300px;
@import "../styles/variables.scss";

.btn-height {
  height: 167px;
}

.round-top {
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
}

.round {
  border-radius: 2em;
}

.idle-bg {
  background-image: url("../assets/recharge/idle-bk-r.png");
  background-size: cover;
}

.home-bg {
  background-color: white;

  .btn {
    min-height: 250px;
  }

  .btn-small {
    min-height: 200px;
  }
}

.start-bg {
  background-image: url("../assets/start-bk.png");
  background-size: cover;
}

.text-huge {
  font-size: 78px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: $primary;
}
.text-home-huge {
  font-size: 80px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.heading-2 {
  font-size: 40px;
}

.white-icon {
  filter: brightness(0) invert(1);
}

.connector-borders {
  border: 3px solid $primary;
  opacity: 1;
}

.bg-connectors {
  background-color: $primary;
  opacity: 0.8;
}

.recharge-logo {
  position: absolute;
  bottom: 400px;
  width: 300px;
}

.view-height {
  height: calc(100vh - #{$footer-height}); //minus footer
}

.start-div {
  height: 500px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.footer {
  min-height: $footer-height;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.btn-outline-none {
  outline: 0;
  box-shadow: none;
  border: 0;

  &:focus {
    outline: 0;
    box-shadow: none;
    border: 0;
  }
}

.select-connector-bg {
  background-image: url("../assets/recharge/select-connector-r.png");
}

.select-connector-id-bg {
  background-color: white;
}

.location-bg {
  background-image: url("../assets/recharge/location-illustration-r.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.adv-bg {
  background-image: url("../assets/adv.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 200px;
}

.adv-2-bg {
  background-image: url("../assets/adv-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 15%;
}

.stop-session-bg {
  background-image: url("../assets/stop-session-bk.png");
  background-size: cover;
  background-position: top;
}

.end-stop-bg {
  background-image: url("../assets/end-stop-bk.png");
  background-size: cover;
  background-position: center;
}

.languageButton {
  width: 70px;
  height: 50px;
}
.svg-secondary {
    // this sets the color as secondary
    filter: invert(53%) sepia(55%) saturate(5747%) hue-rotate(8deg) brightness(111%) contrast(104%);
  }

.svg-primary {
  filter: invert(8%) sepia(58%) saturate(4634%) hue-rotate(204deg) brightness(96%) contrast(101%);
}

.arrow {
  @extend .svg-primary;
  width: 300px;
  height: 300px;
  margin-top: 50px;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}

.receipt-button {
  width: 500px;
  color: white;
  font-size: 100px;
  height: 300px;
  margin-bottom: 20px;
  padding-left: 70px;
  text-align: left;
}
.yes-receipt-button {
  @extend .receipt-button;
  border-top-left-radius: 0.6em;
  border-top-right-radius: 0.6em;
}
.no-receipt-button {
  @extend .receipt-button;
  border-bottom-left-radius: 0.6em;
  border-bottom-right-radius: 0.6em;
}

.tc {
  margin-left: 30px;
  font-size: 50px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 550px;
  align-items: center;
  justify-content: center;
}

.tc-chosen {
  @extend .tc;
  color: $secondary;
}

.tc-text {
  @extend .tc;
  color: $primary;
}

.tcCheckbox {
  border: $secondary 2px solid;
  height: 150px;
  width: 150px;
}

.tc-input-group {
  margin-left: 100px;
  margin-right: 100px;
}

.keyboard {
  position: fixed;
  left: 0;
  bottom: $footer-height;
  width: 100%;
  padding: 5px 0;
  box-shadow: 0 0 50px rgba(0,0,0, 0.5);
  user-select: none;
  transition: bottom 0.8s;
  height: 530px;
  z-index: 3;

  .hg-button {
    height: 100px !important;
    background-color: white;
    color: $primary;
    font-size: 50px !important;
  }
  .hg-button.hg-activeButton {
    box-shadow: 0 0 55px $secondary;
  }
  .hg-button.hg-functionBtn.hg-button-space[data-skbtn="{space}"] {
    width: 400px !important;
  }
  .hg-button.hg-functionBtn.hg-button-enter[data-skbtn="{enter}"] {
    width: 100px !important;
  }

}
